import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { resolveImageUrl } from "../utils/resolveImageUrl";
import gridViewButtonn from '../images/grid-view-button.svg';
import menuIcon from '../images/menu-icon.svg';
import closeIcon from '../images/close-icon.svg';
import circleArrow from '../images/arrow-circle.svg';
import { Link } from "gatsby"

const ProjectPostTemplate = ({
  data: { site, markdownRemark: post, previous, next },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`;
  const attachments = [
    resolveImageUrl(post.frontmatter.attachment1),
    resolveImageUrl(post.frontmatter.attachment2),
    resolveImageUrl(post.frontmatter.attachment3),
    resolveImageUrl(post.frontmatter.attachment4),
    resolveImageUrl(post.frontmatter.attachment5),
  ].filter(Boolean);

  return (
    <Layout location={location} title={siteTitle}>
      <div className="text-sm text-zinc-100 max-w-6xl">
        <article className="mb-10">
          <div className="mb-8 md:mb-20">
            <h1 itemProp="headline" className="font-bold text-4xl md:text-5xl border-b border-white pb-5 mb-5">
              {post.frontmatter.title}
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-5">
            <div className="col-span-2">
              <div className="">
                {attachments.length > 0 && (
                  <div className="">
                    <div className="grid grid-cols-1 gap-5">
                      {attachments.map((attachment, index) => (
                        <div key={index}>
                          <img
                            src={attachment}
                            alt={`Attachment ${index + 1}`}
                            className="w-full"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-1 pt-10 md:pt-0">
              <div className="sticky block top-5">
                <h2 className="font-bold text-2xl mb-5">About This Project</h2>
                <section
                  className="postBody"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-5 mt-10">
            {/* Previous Post Link */}
            <div className="flex justify-center items-center py-5">
              {previous && (
                <Link to={previous.fields.slug} className="">
                  <img src={circleArrow} className="w-14" alt="Previous Project" />
                </Link>
              )}
            </div>

            {/* Grid View Button */}
            <div className="flex justify-center items-center">
              <Link to="/project" className="">
                <img src={gridViewButtonn} className="w-10" alt="Grid View" />
              </Link>
            </div>

            {/* Next Post Link */}
            <div className="flex justify-center items-center py-5">
              {next && (
                <Link to={next.fields.slug} className="">
                  <img src={circleArrow} className="w-14 transform scale-x-[-1]" alt="Next Project" />
                </Link>
              )}
            </div>
          </div>

        </article>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default ProjectPostTemplate

export const query = graphql`
  query ProjectPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 270)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        previewText
        attachment1
        attachment2
        attachment3
        attachment4
        attachment5
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
